.gform_wrapper.gravity-theme {
    .gfield_label {
        font-weight: 600;
        margin-bottom: 0;
    }

    .gfield_required {
        color: #dc2626;
        margin-left: 2px;
    }

    .gform_footer {
        margin-top: 2rem;
    }

    .gform_submission_error {
        --lineHeight: 1;
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        color: #dc2626;
        background-color: #fef2f2;
        border: 1px solid #fca5a5;
        padding: 1rem;
        border-radius: 4px;
        margin-bottom: 12px;

        .gform-icon--close {
            font-size: 2rem;
        }
    }

    .gfield_error {
        input, textarea {
            --form-field-border-initial-color: #dc2626;
        }
    }

    .gfield_validation_message {
        font-size: .8rem;
        color: #dc2626;
    }
}