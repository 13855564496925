.has {
    &-shadow-small {
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.02), 0px 2px 4px rgba(0, 0, 0, 0.08);
    }

	&-shadow-medium {
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04),
			0px 4px 8px rgba(0, 0, 0, 0.06);
	}

	&-shadow-large {
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04),
			0px 8px 16px rgba(0, 0, 0, 0.08);
	}
}

.flex__space-between {
	justify-content: space-between;
}