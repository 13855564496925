/**
* Importing by glob example
* https://github.com/maoberlehner/node-sass-magic-importer/tree/master/packages/node-sass-magic-importer#globbing
*/
@charset "UTF-8";

@import 'abstracts/**/*.scss';
@import 'vendors/**/*.scss';
@import 'base/**/*.scss';
@import 'layout/**/*.scss';
@import 'components/**/*.scss';
@import 'pages/**/*.scss';
